<template>
  <div class="couponList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>已领取优惠券</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <el-row justify="space-between">
    <el-col :span="6" ><div><span>供应商名称:</span>
      <el-input
        v-model.trim="list.supplierName"
        placeholder="请输入供应商"
      ></el-input></div></el-col>
    <el-col :span="6" ><div><span>联系人:</span>
      <el-input
        v-model.trim="list.linkMan"
        placeholder="请输入手机号码"
      ></el-input></div></el-col>
    <el-col :span="6" ><div><span>手机号码:</span>
      <el-input
        v-model.trim="list.telPhone"
        placeholder="请输入手机号码"
      ></el-input></div></el-col>
    <el-col :span="6" ><div><span>结算方式:</span>
      <el-select placeholder="请选择结算方式" v-model="list.payWay" class="select">
                <el-option
                  v-for="item in payway"
                  :key="item.value"
                  :label="item.way"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              </div>
              </el-col>
              <el-col :span="6" >
            <div><span>类型:</span>
      <el-select placeholder="请选择类型" v-model="list.supplierType" class="select">
                <el-option
                  v-for="item in options2"
                  :key="item.value"
                  :label="item.way"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              </div>
              </el-col>
              <el-col :span="6">
     <el-button type="primary" @click="tableList()">查找</el-button>
      <el-button type="primary" @click="$router.push('/addSupplier')">添加</el-button>
              </el-col>
  </el-row>
       <!-- <el-button type="primary" @click="$router.push(`/addCoupon/0`)"
      v-if=" $store.state.powerList.indexOf('coupon:info:list:add') !== -1"
        >添加</el-button
      > -->
    </div>
    <el-table
      @selection-change="onSelectChange"
      :data="formData"
      border
      style="width: 100%"
    >
      <el-table-column
        align="center"
        type="selection"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="supplierId"
        label="供应商Id"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="supplierName"
        label="供应商简称"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="linkMan"
        label="联系人"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="telPhone"
        label="联系电话"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="payWay"
        label="结算方式"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="supplierType"
        label="类型"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="addTime"
        label="添加时间"
        width="180"
      >
      </el-table-column>
      <el-table-column align="center" label="操作" width="300">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            @click="goEdit(row.supplierId)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="onChangeStatus(row.supplierId,2)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { supplierListAPI,delSupplierAPI,addSupplierAPI,supplierAllAPI } from "../../api/Statistics";
export default {
  name: "couponList",
  data() {
    return {
      cateOptions:[],
      payway:[{value:0,way:'全部'},{value:1,way:'预付'},
                {value:2,way:'月结'},
                {value:3,way:'季结'},
                {value:4,way:'周结'}],
      options2:[
            {value:0,way:'全部'},
            {value:1,way:'财务'},
                {value:2,way:'运营'}],
       fit:'fill',
      formData: [],
      pagination: {},
      ids: "",
      options: [
        {
          value: 1,
          label: "手动领取",
        },
        {
          value: 2,
          label: "注册领取",
        },
      ],
      options1: [
        {
          value: -2,
          label: "全部",
        }, {
          value: 0,
          label: "未使用",
        },
        {
          value: 1,
          label: "已使用",
        },
        {
          value: 2,
          label: "已过期",
        },
      ],
      list: {
        currentPage: 1,
        pageSize: 5,
        linkMan: "",
        telPhone: "",
        payWay:'',
        supplierType:'',
        cateId:''
      },
       dialogVisible: false,
       QRCode:'',
    };
  },
  created() {
    this.tableList();
    this.supplierAll()
  },
  methods: {
    async supplierAll(){
      const {data} = await supplierAllAPI()
      this.cateOptions = data.data
    },
     handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {})
     },
     goEdit(id){
       console.log(id,'id')
       this.$router.push(`/addSupplier?id=${id}`)
     },
    async goCouponQRCode(couponId){
      this.dialogVisible = true;
      const {data} = await couponQRCode({
        couponId:couponId,
      })
    if(data.code == 0){
      console.log(data.msg)
      this.QRCode = data.msg
      console.log('图片',this.QRCode)
    }
    },
    async tableList() {
      const { data } = await supplierListAPI(this.list);
      this.formData = data.list;
      this.pagination = data.pagination;
    },
    onChangeStatus(id) {
      this.$confirm(`是否要删除`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await delSupplierAPI({
            supplierId: id,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.tableList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    onDelRow(id) {
      this.$confirm("是否要设置这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await changeStatus({
            ids: id + "",
            status: 0,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.tableList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onSelectChange(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.couponId);
      });
      this.ids = arr.join(",");
    },
    async onChangeAll(status) {
      this.$confirm("是否要批量设置这些数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          if (this.ids == "") {
            return this.$message({
              type: "error",
              message: "请选择要设置的数据",
            });
          }

          const { data } = await changeStatus({
            ids: this.ids,
            status,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.tableList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    onClear(v) {
      if (v == 1) {
        this.list.receiveWay = null;
      } else if (v == 2) {
        this.list.status = null;
      }
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.tableList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.tableList();
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-select-dropdown__list {
  width: 20%;
}
// /deep/.el-input__icon {
//     margin-left: -220px;
// }
.el-col{
  margin:10px 0;
  div{
    display:flex;
    align-items:center;
    span{
      width:100px;
    margin:0 !important;
    display: flex;
    align-items: center;
    // justify-content: center;
    padding-right: 10px;
    box-sizing: border-box;
  }
  .el-input__inner{
    width: 200px !important;
  }
  }
}
  .select{
    width: 60% !important;
  }
.couponList {
  overflow-x:hidden;
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .el-table {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  .search {
    margin-top: 50px;
    .el-input {
      width: 244px;
    }
    span {
      margin: 0 20px 0 40px;
    }
    .el-button:last-child{
      margin-left: 20px;
    }
  }
  .btn {
    margin-top: 50px;
    float: left;
    
  }
}
</style>
import request from '../utils/request'
import baseUrl from './baseUrl'

export const memberByWeek = (data) => request({
  url: baseUrl + '/userInfo/selectOneWeekInfo',
  method: 'POST',
  data
})
// 会员月统计
export const memberByMonth = (data) => request({
  url: baseUrl + '/userInfo/selectUserByMonth',
  method: 'POST',
  data
})
// 会员年统计
export const memberByYear = (data) => request({
  url: baseUrl + '/userInfo/selectUserByYear',
  method: 'POST',
  data
})

export const orderByWeek = (data) => request({
  url: baseUrl + '/order/selectOneWeekInfo',
  method: 'POST',
  data
})
// 订单月统计
export const orderByMonth = (data) => request({
  url: baseUrl + '/order/selectOrderByMonth',
  method: 'POST',
  data
})
// 订单年统计
export const orderByYear = (data) => request({
  url: baseUrl + '/order/selectOrderByYear',
  method: 'POST',
  data
})

/* 商品统计 */
export const productTop = (data) => request({
  url: baseUrl + '/statistics/selectProdOrderNum',
  method: 'POST',
  data
})
/* 会员消费统计 */
export const memberConsume = (data) => request({
  url: baseUrl + '/statistics/selectMemOrderNum',
  method: 'POST',
  data
})
/* 会员复购统计 */
export const memberBuys = (data) => request({
  url: baseUrl + '/statistics/selectMemMoreOrderNum',
  method: 'POST',
  data
})
export const supplierListAPI = (data) => request({//供应商列表
  url: baseUrl + '/supplier/selectForBack',
  method: 'POST',
  data
})
export const addSupplierAPI = (data) => request({//添加供应商
  url: baseUrl + '/supplier/add',
  method: 'POST',
  data
})
export const supplierDetailAPI = (data) => request({//查看供应商详情
  url: baseUrl + '/supplier/selectForId',
  method: 'POST',
  data
})
export const delSupplierAPI = (data) => request({//删除供应商
  url: baseUrl + '/supplier/deleteForId',
  method: 'POST',
  data
})
export const supplierAllAPI = (data) => request({//查看全部
  url: baseUrl + '/supplier/selectAll',
  method: 'POST',
  data
})
export const getBalList = (data) => request({//余额流水
  url: baseUrl + '/balanceInfo/selectForInfo',
  method: 'POST',
  data
})
export const rankingList = (data) => request({
  url: baseUrl + '/userInfo/selectForProfit',
  method: 'POST',
  data
})
export const InpotAPI = (data) => request({//
  url: baseUrl + '/balanceInfo/selectForInfoExcel',
  method: 'POST',
  data,
 
  responseType: "blob"
})
export const importVipList = (data) => request({//
  url: baseUrl + '/statistics/selectMemOrderNumExport',
  method: 'POST',
  data,
 
  responseType: "blob"
})
export const exportUserShop = (data) => request({//
  url: baseUrl + '/userInfo/selectForProfitExcel',
  method: 'POST',
  data,
 
  responseType: "blob"
})

